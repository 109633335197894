var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app-header"}},[(_vm.getUserType === 'admin')?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","color":"#fffcf5"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_vm._l((_vm.navadmin),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","text-center":"","pa-2":"","pl-0":"","text-none":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","px-0":"","pl-2":"","align-self-center":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":item.route}},[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_c('span',{staticStyle:{"font-size":"15px","color":"black","letter-spacing":"1px","cursor":"pointer"},style:(_vm.$route.path == item.route
                      ? {
                        'font-family': 'mainfont',
                      }
                      : {
                        'font-family': 'mainfont',
                      })},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)],1)],1)}),_c('v-flex',{attrs:{"xs12":"","text-center":"","pa-2":"","pl-0":"","text-none":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","px-0":"","pl-3":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","letter-spacing":"1px","cursor":"pointer"},on:{"click":function($event){return _vm.appLogout()}}},[_vm._v("Logout")])])],1)],1)],2)],1),_c('v-app-bar',{staticClass:"hidden-lg-and-up bgcustom",attrs:{"app":"","dark":"","bgcustom":"","dense":"","flat":"","height":"50px"}},[_c('v-app-bar-nav-icon',{nativeOn:{"click":function($event){_vm.sideNav = !_vm.sideNav}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-menu")])],1),_c('v-toolbar-title',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","font-size":"14px","cursor":"pointer","color":"#ffffff"}},[_vm._v(" NATURESTAY ")])])],1)],1)],1)],1),_c('v-app-bar',{staticClass:"hidden-sm-and-down bgcustom transparent",attrs:{"fixed":"","app":"","sticky":"","elevation":"0","height":"90px","grad":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","text-center":""}},[_c('v-flex',{attrs:{"xs1":"","pl-2":"","align-self-center":""}},[_c('v-img',{attrs:{"height":"60px","contain":"","src":require("../../assets/Images/logowhite.png")}})],1),_c('v-flex',{attrs:{"xs3":"","pl-2":"","text-left":"","align-self-center":""},on:{"click":_vm.gotoHome}},[_c('span',{staticClass:"mainfont",staticStyle:{"font-weight":"25px","font-size":"larger","color":"white"}},[_vm._v("NATURESTAY")])]),_c('v-flex',{attrs:{"xs6":"","lg0":"","sm8":"","pt-4":"","pr-10":"","text-right":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mainfont",attrs:{"outlined":"","color":"white","small":""}},on),[_c('v-icon',{attrs:{"color":"white","size":"19"}},[_vm._v("mdi-account-circle-outline")]),_c('span',{staticStyle:{"font-family":"mainfont","color":"white","font-size":"12px"}},[_vm._v(_vm._s(_vm.userProfile))])],1)]}}],null,false,2355677146)},[_c('v-list',[_c('v-list-item',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","color":"black","font-size":"14px"}},[_vm._v("Role: "+_vm._s(_vm.userRole))])])],1),_c('v-list-item',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","color":"black","font-size":"14px"}},[_vm._v("Name: "+_vm._s(_vm.userName))])])],1),_c('v-flex',{staticClass:"text-center",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":_vm.appLogout}},[_vm._v("Logout")])],1)],1)],1)],1)],1)],1)],1)],1):(_vm.getUserType === 'user')?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","color":"#fffcf5"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_vm._l((_vm.navuser),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","text-center":"","pa-2":"","pl-0":"","text-none":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","px-0":"","pl-2":"","align-self-center":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":item.route}},[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_c('span',{staticStyle:{"font-size":"15px","color":"black","letter-spacing":"1px","cursor":"pointer"},style:(_vm.$route.path == item.route
                      ? {
                        'font-family': 'mainfont',
                      }
                      : {
                        'font-family': 'mainfont',
                      })},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)],1)],1)}),_c('v-flex',{attrs:{"xs12":"","text-center":"","pa-2":"","pl-0":"","text-none":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","px-0":"","pl-3":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","letter-spacing":"1px","cursor":"pointer"},on:{"click":function($event){return _vm.appLogout()}}},[_vm._v("Logout")])])],1)],1)],2)],1),_c('v-app-bar',{staticClass:"hidden-lg-and-up bgcustom",attrs:{"app":"","dark":"","bgcustom":"","dense":"","flat":"","height":"50px"}},[_c('v-app-bar-nav-icon',{nativeOn:{"click":function($event){_vm.sideNav = !_vm.sideNav}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-menu")])],1),_c('v-toolbar-title',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","font-size":"14px","cursor":"pointer","color":"#ffffff"}},[_vm._v(" NATURESTAY ")])])],1)],1)],1)],1),_c('v-app-bar',{staticClass:"hidden-sm-and-down bgcustom transparent",attrs:{"fixed":"","app":"","sticky":"","elevation":"0","height":"90px","grad":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","text-center":""}},[_c('v-flex',{attrs:{"xs1":"","pl-2":"","align-self-center":""}},[_c('v-img',{attrs:{"height":"60px","contain":"","src":require("../../assets/Images/logowhite.png")}})],1),_c('v-flex',{attrs:{"xs3":"","pl-2":"","text-left":"","align-self-center":""},on:{"click":_vm.gotoHome}},[_c('span',{staticClass:"mainfont",staticStyle:{"font-weight":"23px","font-size":"larger","color":"white"}},[_vm._v("NATURESTAY")])]),_c('v-flex',{attrs:{"xs6":"","lg0":"","sm8":"","pt-4":"","pr-10":"","text-right":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mainfont",attrs:{"outlined":"","color":"white","small":""}},on),[_c('v-icon',{attrs:{"color":"white","size":"19"}},[_vm._v("mdi-account-circle-outline")]),_c('span',{staticStyle:{"font-family":"mainfont","color":"white","font-size":"12px"}},[_vm._v(_vm._s(_vm.userProfile))])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","color":"black","font-size":"14px"}},[_vm._v("Role: "+_vm._s(_vm.userRole))])])],1),_c('v-list-item',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","color":"black","font-size":"14px"}},[_vm._v("Name: "+_vm._s(_vm.userName))])])],1),_c('v-flex',{staticClass:"text-center",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":_vm.appLogout}},[_vm._v("Logout")])],1)],1)],1)],1)],1)],1)],1)],1):(_vm.getUserType === 'clerk')?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","color":"#fffcf5"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_vm._l((_vm.navclerk),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","text-center":"","pa-2":"","pl-0":"","text-none":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","px-0":"","pl-2":"","align-self-center":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":item.route}},[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_c('span',{staticStyle:{"font-size":"15px","color":"black","letter-spacing":"1px","cursor":"pointer"},style:(_vm.$route.path == item.route
                      ? {
                        'font-family': 'mainfont',
                      }
                      : {
                        'font-family': 'mainfont',
                      })},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)],1)],1)}),_c('v-flex',{attrs:{"xs12":"","text-center":"","pa-2":"","pl-0":"","text-none":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","px-0":"","pl-3":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","letter-spacing":"1px","cursor":"pointer"},on:{"click":function($event){return _vm.appLogout()}}},[_vm._v("Logout")])])],1)],1)],2)],1),_c('v-app-bar',{staticClass:"hidden-lg-and-up bgcustom",attrs:{"app":"","dark":"","bgcustom":"","dense":"","flat":"","height":"50px"}},[_c('v-app-bar-nav-icon',{nativeOn:{"click":function($event){_vm.sideNav = !_vm.sideNav}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-menu")])],1),_c('v-toolbar-title',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","font-size":"14px","cursor":"pointer","color":"#ffffff"}},[_vm._v(" NATURESTAY ")])])],1)],1)],1)],1),_c('v-app-bar',{staticClass:"hidden-sm-and-down bgcustom transparent",attrs:{"fixed":"","app":"","sticky":"","elevation":"0","height":"90px","grad":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","text-center":""}},[_c('v-flex',{attrs:{"xs1":"","pl-2":"","align-self-center":""}},[_c('v-img',{attrs:{"height":"60px","contain":"","src":require("../../assets/Images/logowhite.png")}})],1),_c('v-flex',{attrs:{"xs3":"","pl-2":"","text-left":"","align-self-center":""},on:{"click":_vm.gotoHome}},[_c('span',{staticClass:"mainfont",staticStyle:{"font-weight":"23px","font-size":"larger","color":"white"}},[_vm._v("NATURESTAY")])]),_c('v-flex',{attrs:{"xs6":"","lg0":"","sm8":"","pt-4":"","pr-10":"","text-right":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mainfont",attrs:{"outlined":"","color":"white","small":""}},on),[_c('v-icon',{attrs:{"color":"white","size":"19"}},[_vm._v("mdi-account-circle-outline")]),_c('span',{staticStyle:{"font-family":"mainfont","color":"white","font-size":"12px"}},[_vm._v(_vm._s(_vm.userProfile))])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","color":"black","font-size":"14px"}},[_vm._v("Role: "+_vm._s(_vm.userRole))])])],1),_c('v-list-item',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","color":"black","font-size":"14px"}},[_vm._v("Name: "+_vm._s(_vm.userName))])])],1),_c('v-flex',{staticClass:"text-center",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":_vm.appLogout}},[_vm._v("Logout")])],1)],1)],1)],1)],1)],1)],1)],1):(_vm.getUserType === 'superint')?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","color":"#fffcf5"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_vm._l((_vm.navsuperint),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","text-center":"","pa-2":"","pl-0":"","text-none":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","px-0":"","pl-2":"","align-self-center":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":item.route}},[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_c('span',{staticStyle:{"font-size":"15px","color":"black","letter-spacing":"1px","cursor":"pointer"},style:(_vm.$route.path == item.route
                      ? {
                        'font-family': 'mainfont',
                      }
                      : {
                        'font-family': 'mainfont',
                      })},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)],1)],1)}),_c('v-flex',{attrs:{"xs12":"","text-center":"","pa-2":"","pl-0":"","text-none":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","px-0":"","pl-3":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","letter-spacing":"1px","cursor":"pointer"},on:{"click":function($event){return _vm.appLogout()}}},[_vm._v("Logout")])])],1)],1)],2)],1),_c('v-app-bar',{staticClass:"hidden-lg-and-up bgcustom",attrs:{"app":"","dark":"","bgcustom":"","dense":"","flat":"","height":"50px"}},[_c('v-app-bar-nav-icon',{nativeOn:{"click":function($event){_vm.sideNav = !_vm.sideNav}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-menu")])],1),_c('v-toolbar-title',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","font-size":"14px","cursor":"pointer","color":"#ffffff"}},[_vm._v(" NATURESTAY ")])])],1)],1)],1)],1),_c('v-app-bar',{staticClass:"hidden-sm-and-down bgcustom transparent",attrs:{"fixed":"","app":"","sticky":"","elevation":"0","height":"90px","grad":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","text-center":""}},[_c('v-flex',{attrs:{"xs1":"","pl-2":"","align-self-center":""}},[_c('v-img',{attrs:{"height":"60px","contain":"","src":require("../../assets/Images/logowhite.png")}})],1),_c('v-flex',{attrs:{"xs3":"","pl-2":"","text-left":"","align-self-center":""},on:{"click":_vm.gotoHome}},[_c('span',{staticClass:"mainfont",staticStyle:{"font-weight":"23px","font-size":"larger","color":"white"}},[_vm._v("NATURESTAY")])]),_c('v-flex',{attrs:{"xs6":"","lg0":"","sm8":"","pt-4":"","pr-10":"","text-right":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mainfont",attrs:{"outlined":"","color":"white","small":""}},on),[_c('v-icon',{attrs:{"color":"white","size":"19"}},[_vm._v("mdi-account-circle-outline")]),_c('span',{staticStyle:{"font-family":"mainfont","color":"white","font-size":"12px"}},[_vm._v(_vm._s(_vm.userProfile))])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","color":"black","font-size":"14px"}},[_vm._v("Role: "+_vm._s(_vm.userRole))])])],1),_c('v-list-item',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","color":"black","font-size":"14px"}},[_vm._v("Name: "+_vm._s(_vm.userName))])])],1),_c('v-flex',{staticClass:"text-center",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":_vm.appLogout}},[_vm._v("Logout")])],1)],1)],1)],1)],1)],1)],1)],1):_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","color":"#fffcf5"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_vm._l((_vm.navdivision),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","text-center":"","pa-2":"","pl-0":"","text-none":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","px-0":"","pl-2":"","align-self-center":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":item.route}},[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_c('span',{staticStyle:{"font-size":"15px","color":"black","letter-spacing":"1px","cursor":"pointer"},style:(_vm.$route.path == item.route
                      ? {
                        'font-family': 'mainfont',
                      }
                      : {
                        'font-family': 'mainfont',
                      })},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)],1)],1)}),_c('v-flex',{attrs:{"xs12":"","text-center":"","pa-2":"","pl-0":"","text-none":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","px-0":"","pl-3":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","letter-spacing":"1px","cursor":"pointer"},on:{"click":function($event){return _vm.appLogout()}}},[_vm._v("Logout")])])],1)],1)],2)],1),_c('v-app-bar',{staticClass:"hidden-lg-and-up bgcustom",attrs:{"app":"","dark":"","bgcustom":"","dense":"","flat":"","height":"50px"}},[_c('v-app-bar-nav-icon',{nativeOn:{"click":function($event){_vm.sideNav = !_vm.sideNav}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-menu")])],1),_c('v-toolbar-title',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","font-size":"14px","cursor":"pointer","color":"#ffffff"}},[_vm._v(" NATURESTAY ")])])],1)],1)],1)],1),_c('v-app-bar',{staticClass:"hidden-sm-and-down bgcustom transparent",attrs:{"fixed":"","app":"","sticky":"","elevation":"0","height":"90px","grad":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","text-center":""}},[_c('v-flex',{attrs:{"xs1":"","pl-2":"","align-self-center":""}},[_c('v-img',{attrs:{"height":"60px","contain":"","src":require("../../assets/Images/logowhite.png")}})],1),_c('v-flex',{attrs:{"xs3":"","pl-2":"","text-left":"","align-self-center":""},on:{"click":_vm.gotoHome}},[_c('span',{staticClass:"mainfont",staticStyle:{"font-size":"larger","color":"white"}},[_vm._v("NATURESTAY")])]),_c('v-flex',{attrs:{"xs6":"","lg0":"","sm8":"","pt-4":"","pr-10":"","text-right":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mainfont",attrs:{"outlined":"","color":"white","small":""}},on),[_c('v-icon',{attrs:{"color":"white","size":"19"}},[_vm._v("mdi-account-circle-outline")]),_c('span',{staticStyle:{"font-family":"mainfont","color":"white","font-size":"12px"}},[_vm._v(_vm._s(_vm.userProfile))])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","color":"black","font-size":"14px"}},[_vm._v("Role: "+_vm._s(_vm.userRole))])])],1),_c('v-list-item',[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"mainfont","color":"black","font-size":"14px"}},[_vm._v("Name: "+_vm._s(_vm.userName))])])],1),_c('v-flex',{staticClass:"text-center",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":_vm.appLogout}},[_vm._v("Logout")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }