<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#283e51"
        spinner="spinner"
      />
      <v-flex>
        <v-layout wrap justify-center>
          <v-flex xs11 text-center pt-7 pb-2>
            <span
              style="
                font-size: 23px;
                font-weight: bold;
                text-transform: uppercase;
              "
            >
              Edit Application form for online reservation of
              {{ campview.campName }}</span
            >
          </v-flex>
          <v-flex pa-4 xs12 text-center>
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs12 text-center>
                  <v-card>
                    <v-layout wrap justify-center>
                      <v-flex
                        pl-4
                        pt-2
                        pb-2
                        xs12
                        text-left
                        style="background-color: black"
                      >
                        <span style="font-size: 20px; color: white"
                          >Select Date and Duration</span
                        >
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex pl-3 xs4 text-left align-self-center>
                            <v-autocomplete
                              disabled
                              outlined
                              dense
                              color="black"
                              :items="districts"
                              v-model="dist"
                              item-text="name"
                              item-value="name"
                              item-color="#FF1313"
                              hide-details
                              clearable
                              class="custom-autocomplete mainfont"
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                  >District
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                              <template v-slot:append>
                                <v-icon color="black">mdi-chevron-down</v-icon>
                              </template>
                            </v-autocomplete>
                          </v-flex>

                          <v-flex xs4 pl-3 align-self-center pt-6>
                            <v-menu
                              ref="menu1"
                              v-model="menu1"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  clearable
                                  dense
                                  outlined
                                  readonly
                                  v-model="cdate"
                                  v-bind="attrs"
                                  background-color="white"
                                  v-on="on"
                                  @click:clear="fromDate = null"
                                  style="color: black"
                                >
                                  <template v-slot:append>
                                    <v-icon color="black"
                                      >mdi-chevron-down</v-icon
                                    >
                                  </template>
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                      >From Date</span
                                    >
                                  </template>
                                </v-text-field>
                              </template>
                              <v-date-picker
                                @input="checkAvailability(cdate)"
                                :min="minDate"
                                v-model="cdate"
                                color="#13736f"
                                @change="menu1 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs4>
                            <v-layout wrap justify-center>
                              <v-flex xs6 align-self-center>
                                <span
                                  >Duration of Camp
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </v-flex>

                              <v-flex xs4>
                                <v-radio-group v-model="list.campDuration">
                                  <v-radio label="1 days" value="1"></v-radio>
                                  <v-radio label="2 days" value="2"></v-radio>
                                  <v-radio label="3 days" value="3"></v-radio>
                                </v-radio-group>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap pb-2>
                          <v-flex xs11 text-right>
                            <span>
                              Selected Duration of Camp is
                              {{ list.campDuration }} days and the amount is
                              {{ totalAmount }} ₹
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <!-- <v-flex xs6>
                        Selected camp on {{ startDate }} can accommodate people as follows
                      </v-flex>
                      <v-flex xs8 pt-3 pb-9>

                        <v-card elevation="0" style="border: 1px solid black; border-radius: 0px;">
                          <v-layout wrap pt-3 pb-3 justify-center>
                            <v-flex xs12>
                              <v-layout wrap justify-center>
                                <v-flex xs3 pt-2 text-center>
                                  <span class="subheading">Staff/Group Leader</span>
                                </v-flex>
                                <v-flex pt-2 xs3 text-center>
                                  <span class="subheading">Male Members</span>
                                </v-flex>
                                <v-flex pt-2 xs3 text-center>
                                  <span class="subheading">Female Members</span>
                                </v-flex>
                                <v-flex pt-2 xs3 text-center>
                                  <span class="subheading">Transgender Members</span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 pt-3 pb-3>
                              <v-divider></v-divider>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap justify-center>
                                <v-flex v-if="list" pb-2 xs3 text-center>
                                  <span v-if="list.campid">
                                    <span v-if="list.campid.totalMembers">{{ list.campid.totalMembers }}</span>

                                  </span>

                                </v-flex>
                                <v-flex v-if="list" pb-2 xs3 text-center>


                                  <span v-if="list.campid">
                                    <span v-if="list.campid.membersLimit">
                                      <span v-if="list.campid.membersLimit.male">
                                        {{ list.campid.membersLimit.male }}

                                      </span>

                                    </span>

                                  </span>
                                </v-flex>
                                <v-flex v-if="list" pb-2 xs3 text-center>
                                  <span v-if="list.campid">
                                    <span v-if="list.campid.membersLimit">
                                      <span v-if="list.campid.membersLimit.male">
                                        {{ list.campid.membersLimit.male }}
                                      </span>
                                    </span>
                                  </span>
                                </v-flex>
                                <v-flex v-if="list" pb-2 xs3 text-center>
                                  <span v-if="list.campid">
                                    <span v-if="list.campid.membersLimit">
                                      <span v-if="list.campid.membersLimit.transgender">
                                        {{ list.campid.membersLimit.transgender }}
                                      </span>
                                    </span>
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex> -->
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex pa-4 xs12 text-center>
            <v-card>
              <v-layout wrap>
                <v-flex xs12 text-center>
                  <v-card>
                    <v-layout wrap justify-center>
                      <v-flex
                        pl-4
                        pt-2
                        pb-2
                        xs12
                        text-left
                        style="background-color: black"
                      >
                        <span style="font-size: 20px; color: white">
                          Applicant Details
                        </span>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap>
                          <!-- <v-flex
                            pl-3
                            xs3
                            pt-6
                            pb-6
                            text-left
                            align-self-center
                          >
                            <v-text-field
                              type="number"
                              dense
                              color="#283e51"
                              outlined
                              disabled
                              background-color="white"
                              :value="totalParticipants"
                              v-model="list.memberCount"
                              hide-details
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                >
                                Total Number of Participants<span
                                    style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                            </v-text-field>
                          </v-flex> -->
                          <!-- <v-flex
                            pl-3
                            xs3
                            pt-6
                            pb-6
                            text-left
                            align-self-center
                          >
                            <v-text-field
                              type="number"
                              dense
                              color="#283e51"
                              outlined
                              class="inputPrice"
                              background-color="white"
                              v-model="list.maleCount"
                              hide-details
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                >
                                 Number of Males<span
                                    style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                            </v-text-field>
                          </v-flex> -->
                          <!-- <v-flex
                            pl-3
                            xs3
                            pt-6
                            pb-6
                            text-left
                            align-self-center
                          >
                            <v-text-field
                              type="number"
                              dense
                              color="#283e51"
                              outlined
                              class="inputPrice"
                              background-color="white"
                              v-model="list.femaleCount"
                              hide-details
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                >
                                 Number of Females<span
                                    style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                            </v-text-field>
                          </v-flex> -->
                          <!-- <v-flex
                            pl-3
                            xs3
                            pt-6
                            pb-6
                            text-left
                            align-self-center
                          >
                            <v-text-field
                              type="number"
                              dense
                              color="#283e51"
                              outlined
                              class="inputPrice"
                              background-color="white"
                              v-model="list.transCount"
                              hide-details
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                >
                                 Number of Transgenders<span
                                    style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                            </v-text-field>
                          </v-flex> -->
                          <v-flex
                            pl-3
                            xs3
                            pt-6
                            pb-6
                            text-left
                            align-self-center
                          >
                            <v-autocomplete
                              outlined
                              dense
                              color="black"
                              :items="applicantcategorys"
                              v-model="list.applicantCategory"
                              item-text="name"
                              item-value="name"
                              item-color="#FF1313"
                              hide-details
                              clearable
                              class="custom-autocomplete mainfont"
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                >
                                  Applicant Category
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                              <template v-slot:append>
                                <v-icon color="black">mdi-chevron-down</v-icon>
                              </template>
                            </v-autocomplete>
                          </v-flex>
                          <v-flex
                            pl-3
                            xs3
                            pt-6
                            pb-6
                            text-left
                            align-self-center
                            v-if="list.applicantCategory === 'Students'"
                          >
                            <v-autocomplete
                              outlined
                              dense
                              color="black"
                              :items="studentCategories"
                              v-model="list.studentCategory"
                              item-text="name"
                              item-value="name"
                              item-color="#FF1313"
                              hide-details
                              clearable
                              class="custom-autocomplete mainfont"
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                >
                                  studentcategory
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                              <template v-slot:append>
                                <v-icon color="black">mdi-chevron-down</v-icon>
                              </template>
                            </v-autocomplete>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex
                            pl-3
                            xs3
                            pt-6
                            pb-6
                            text-left
                            align-self-center
                          >
                            <v-text-field
                              type="text"
                              dense
                              color="#283e51"
                              outlined
                              background-color="white"
                              v-model="list.instName"
                              hide-details
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 13px"
                                >
                                  Name of the Institution/Organisation
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                            </v-text-field>
                          </v-flex>

                          <v-flex
                            v-if="list.applicantCategory === 'Students'"
                            pl-3
                            xs3
                            pt-6
                            pb-6
                            text-left
                            align-self-center
                          >
                            <v-text-field
                              dense
                              color="#283e51"
                              outlined
                              background-color="white"
                              v-model="list.instCode"
                              hide-details
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                >
                                  Institution/Organisation code
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                            </v-text-field>
                          </v-flex>

                          <v-flex
                            pl-3
                            xs3
                            pt-6
                            pb-6
                            text-left
                            align-self-center
                          >
                            <v-autocomplete
                              outlined
                              dense
                              color="black"
                              :items="institutionTypes"
                              v-model="list.instType"
                              item-text="name"
                              item-value="name"
                              item-color="#FF1313"
                              hide-details
                              clearable
                              class="custom-autocomplete mainfont"
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                >
                                  Type of Institution/Organisation
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                              <template v-slot:append>
                                <v-icon color="black">mdi-chevron-down</v-icon>
                              </template>
                            </v-autocomplete>
                          </v-flex>
                          <v-flex
                            v-if="list.applicantCategory !== 'Students'"
                            pl-3
                            xs3
                            pt-6
                            pb-6
                            pr-3
                            text-left
                            align-self-center
                          >
                            <v-text-field
                              type="number"
                              dense
                              color="#283e51"
                              outlined
                              background-color="white"
                              v-model="list.instTeleNo"
                              hide-details
                              class="inputPrice"
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                >
                                Institution/Organisation Phone Number
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                            </v-text-field>
                          </v-flex>
                          <v-flex
                            pl-3
                            xs3
                            pt-9
                            pr-3
                            pb-6
                            text-left
                            align-self-center
                          >
                            <v-text-field
                              color="#283e51"
                              outlined
                              background-color="white"
                              v-model="list.instAddress"
                              hide-details
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 13px"
                                >
                                  Address of the Institution/Organisation
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                            </v-text-field>
                          </v-flex>

                          <v-flex
                            v-if="list.applicantCategory === 'Students'"
                            pl-3
                            xs3
                            pt-3
                            pb-6
                            text-left
                            align-self-center
                          >
                            <v-text-field
                              type="number"
                              dense
                              color="#283e51"
                              outlined
                              background-color="white"
                              v-model="list.instTeleNo"
                              class="inputPrice"
                              :rules="[
                                (v) => !!v || 'Phone number is required',
                                (v) =>
                                  /^[0-9]{11}$/.test(v) ||
                                  'Phone number must have 11 digits',
                              ]"
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                >
                                Institution/Organisation Phone Number
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                            </v-text-field>
                          </v-flex>

                          <v-flex
                            pl-3
                            xs3
                            pt-3
                            pb-6
                            text-left
                            align-self-center
                          >
                            <v-text-field
                              type="number"
                              :rules="PhoneRules"
                              dense
                              color="#283e51"
                              outlined
                              background-color="white"
                              v-model="list.instMobileNo"
                              class="inputPrice"
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                >
                                Institution/Organisation Mobile Number
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                            </v-text-field>
                          </v-flex>
                          <v-flex
                            pl-3
                            xs3
                            pt-3
                            pb-6
                            text-left
                            align-self-center
                          >
                            <v-text-field
                              :rules="emailRules"
                              dense
                              color="#283e51"
                              outlined
                              background-color="white"
                              v-model="list.instEmail"
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                >
                                Institution/Organisation Email<span
                                    style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                            </v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>

          <!-- <v-flex pa-4 xs12 text-center>
            <v-card>
              <template>
                <div>
                  <v-layout wrap>
                    <v-flex xs12 text-center>
                      <v-card>
                        <v-layout wrap justify-center>
                          <v-flex
                            pl-4
                            pt-2
                            pb-2
                            xs12
                            text-left
                            style="background-color: black"
                          >
                            <span style="font-size: 20px; color: white">
                              Institution Details
                            </span>
                          </v-flex>
                          <v-flex xs12>
                            <v-layout wrap>
                              <v-flex
                                pl-3
                                xs3
                                pt-6
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  type="text"
                                  dense
                                  color="#283e51"
                                  outlined
                                  background-color="white"
                                  v-model="list.instName"
                                  hide-details
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 13px"
                                    >
                                      Name of the Institution/Organisation
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>

                              <v-flex
                                pl-3
                                xs3
                                pt-6
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  dense
                                  color="#283e51"
                                  outlined
                                  background-color="white"
                                  v-model="list.instCode"
                                  hide-details
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Institution/Organisation code
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>

                              <v-flex
                                pl-3
                                xs3
                                pt-6
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-autocomplete
                                  outlined
                                  dense
                                  color="black"
                                  :items="institutionTypes"
                                  v-model="list.instType"
                                  item-text="name"
                                  item-value="name"
                                  item-color="#FF1313"
                                  hide-details
                                  clearable
                                  class="custom-autocomplete mainfont"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Type of Institution
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                  <template v-slot:append>
                                    <v-icon color="black"
                                      >mdi-chevron-down</v-icon
                                    >
                                  </template>
                                </v-autocomplete>
                              </v-flex>

                              <v-flex
                                pl-3
                                xs3
                                pt-9
                                pr-3
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  color="#283e51"
                                  outlined
                                  background-color="white"
                                  v-model="list.instAddress"
                                  hide-details
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 13px"
                                    >
                                      Address of the Institution/Organisation
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>

                              
                              <v-flex
                                pl-3
                                xs3
                                pt-3
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  type="number"
                                  dense
                                  color="#283e51"
                                  outlined
                                  background-color="white"
                                  v-model="list.instTeleNo"
                                  hide-details
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Institution Phone Number
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>
                              <v-flex
                                pl-3
                                xs3
                                pt-3
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  type="number"
                                  :rules="PhoneRules"
                                  dense
                                  color="#283e51"
                                  outlined
                                  background-color="white"
                                  v-model="list.instMobileNo"
                                  hide-details
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Institution Mobile Number
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>
                              <v-flex
                                pl-3
                                xs3
                                pt-3
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  :rules="emailRules"
                                  dense
                                  color="#283e51"
                                  outlined
                                  background-color="white"
                                  v-model="list.instEmail"
                                  hide-details
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Institution Email<span
                                        style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </div>
              </template>
            </v-card>
          </v-flex> -->

          <v-flex pa-4 xs12 text-center>
            <v-card>
              <template>
                <div>
                  <v-layout wrap>
                    <v-flex xs12 text-center>
                      <v-card>
                        <v-layout wrap justify-center>
                          <v-flex
                            pl-4
                            pt-2
                            pb-2
                            xs12
                            text-left
                            style="background-color: black"
                          >
                            <span style="font-size: 20px; color: white"
                              >Details of Head of Institution</span
                            >
                          </v-flex>
                          <v-flex xs12>
                            <v-layout wrap>
                              <v-flex
                                pl-3
                                xs3
                                pt-6
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  dense
                                  color="#283e51"
                                  outlined
                                  background-color="white"
                                  v-model="list.instHeadName"
                                  hide-details
                                  @input="filterNonNumericInput"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                      >Name of Head of Institution/Organisation
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>

                              <v-flex
                                pl-3
                                xs3
                                pt-6
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  dense
                                  color="#283e51"
                                  outlined
                                  background-color="white"
                                  v-model="list.headDesignation"
                                  hide-details
                                  @input="filterNonNumericInput"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                      >Designation in Institute /Organisation
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>

                              <v-flex
                                pl-3
                                xs3
                                pt-6
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-autocomplete
                                  outlined
                                  dense
                                  color="black"
                                  :items="idcardtypes"
                                  v-model="list.idCardType"
                                  item-text="name"
                                  item-value="name"
                                  item-color="#FF1313"
                                  hide-details
                                  clearable
                                  class="custom-autocomplete mainfont"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                      >id card type<span
                                        style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                  <template v-slot:append>
                                    <v-icon color="black"
                                      >mdi-chevron-down</v-icon
                                    >
                                  </template>
                                </v-autocomplete>
                              </v-flex>

                              <v-flex
                                pl-3
                                xs3
                                pt-6
                                pb-6
                                pr-3
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  type="number"
                                  dense
                                  color="#283e51"
                                  outlined
                                  background-color="white"
                                  v-model="list.idNo"
                                  hide-details
                                  class="inputPrice"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                      >Id Card Number
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </div>
              </template>
            </v-card>
          </v-flex>

          <v-flex pa-4 xs12 text-center>
            <v-card>
              <template>
                <div>
                  <v-layout wrap>
                    <v-flex
                      pl-4
                      pt-2
                      pb-2
                      text-left
                      style="background-color: black"
                    >
                      <v-layout wrap justify-start>
                        <v-flex xs5 pt-2 align-self-center>
                          <span style="font-size: 20px; color: white">
                            Add Group Leader/Teaching Staff Details </span
                          >&nbsp;
                          <v-btn
                            small
                            color="#68D389"
                            @click="addLeaderDetails()"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-start style="background-color: white">
                    <v-card flat>
                      <v-layout wrap>
                        <v-flex
                          pt-1
                          v-for="(item, i) in leaderDetails2"
                          :key="i"
                        >
                          <v-layout wrap pt-2>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                v-model="item.leaderName"
                                dense
                                outlined
                                label="Name"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                    >Name</span
                                  >
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                v-model="item.leaderAddress1"
                                dense
                                outlined
                                label="Address1"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                    >Address1</span
                                  >
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                v-model="item.leaderAddress2"
                                dense
                                outlined
                                label="Address2"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                    >Address2</span
                                  >
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                color="black"
                                v-model="item.telephone"
                                type="number"
                                dense
                                outlined
                                label="Telephone"
                                hide-details="auto"
                                classs="inputprice"
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                    >Telephone</span
                                  >
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                :rules="PhoneRules"
                                v-model="item.leaderPhone"
                                type="number"
                                dense
                                outlined
                                label="Phone"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Phone<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                color="black"
                                :rules="emailRules"
                                v-model="item.leaderEmail"
                                dense
                                outlined
                                label="Email"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Email<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-autocomplete
                                outlined
                                dense
                                color="black"
                                :items="gender"
                                v-model="item.leaderGender"
                                item-text="name"
                                item-value="name"
                                item-color="#FF1313"
                                hide-details
                                clearable
                                class="custom-autocomplete mainfont"
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Gender<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                                <template v-slot:append>
                                  <v-icon color="black"
                                    >mdi-chevron-down</v-icon
                                  >
                                </template>
                              </v-autocomplete>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <!-- Add Remove Button -->
                              <v-btn
                                dark
                                style="background-color: red"
                                dense
                                @click="removeLeaderDetail(i)"
                              >
                                Remove
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-layout>
                  <v-layout wrap justify-start style="background-color: white">
                    <v-card flat>
                      <v-layout wrap>
                        <v-flex
                          pt-1
                          v-for="(item, i) in leaderDetails"
                          :key="i"
                        >
                          <v-layout wrap pt-2>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                v-model="item.leaderName"
                                dense
                                outlined
                                label="Name"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Name</span
                                  >
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                v-model="item.leaderAddress1"
                                dense
                                outlined
                                label="Address1"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Address1</span
                                  >
                                </template>
                              </v-text-field>
                            </v-flex>

                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                v-model="item.leaderAddress2"
                                dense
                                outlined
                                label="Address2"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Address2</span
                                  >
                                </template>
                              </v-text-field>
                            </v-flex>

                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                color="black"
                                v-model="item.telephone"
                                dense
                                outlined
                                label="Telephone"
                                hide-details
                                class="inputPrice"
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Telephone</span
                                  >
                                </template>
                              </v-text-field>
                            </v-flex>

                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                :rules="PhoneRules"
                                v-model="item.leaderPhone"
                                dense
                                outlined
                                label="Phone"
                                hide-details
                                class="inputPrice"
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Mobile<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>

                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                color="black"
                                :rules="emailRules"
                                v-model="item.leaderEmail"
                                dense
                                outlined
                                label="Email"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Email<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>

                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-autocomplete
                                outlined
                                dense
                                color="black"
                                :items="gender"
                                v-model="item.leaderGender"
                                item-text="name"
                                item-value="name"
                                item-color="#FF1313"
                                hide-details
                                clearable
                                class="custom-autocomplete mainfont"
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Gender<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                                <template v-slot:append>
                                  <v-icon color="black"
                                    >mdi-chevron-down</v-icon
                                  >
                                </template>
                              </v-autocomplete>
                            </v-flex>
                            <v-flex lg2>
                              <center>
                                <v-btn
                                  dark
                                  style="background-color: #68d389"
                                  dense
                                  @click="removeleaderdetails(i)"
                                >
                                  Remove
                                </v-btn>
                              </center>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-layout>
                </div>
              </template>
            </v-card>
          </v-flex>

          <v-flex pa-4 xs12 text-center>
            <v-card>
              <template>
                <div>
                  <v-layout wrap>
                    <v-flex
                      pl-4
                      pt-2
                      pb-2
                      text-left
                      style="background-color: black"
                    >
                      <v-layout wrap justify-start>
                        <v-flex xs4 pt-2 align-self-center>
                          <span style="font-size: 20px; color: white">
                            Add Member Details </span
                          >&nbsp;
                          <v-btn small color="#68D389" @click="addValues()">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <v-layout wrap justify-start style="background-color: white">
                    <v-card flat>
                      <v-layout wrap>
                        <v-flex
                          pt-1
                          v-for="(item, i) in memberDetails2"
                          :key="i"
                        >
                          <v-layout wrap pt-2>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                v-model="item.memberName"
                                dense
                                outlined
                                label="Member Name"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Member Name<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                color="black"
                                v-model="item.memberDob"
                                dense
                                outlined
                                label="memberDob"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    DOB ( DD-MM-YYYY )<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-autocomplete
                                outlined
                                dense
                                color="black"
                                :items="gender"
                                v-model="item.memberGender"
                                item-text="name"
                                item-value="name"
                                item-color="#FF1313"
                                hide-details
                                clearable
                                class="custom-autocomplete mainfont"
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Gender<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                                <template v-slot:append>
                                  <v-icon color="black"
                                    >mdi-chevron-down</v-icon
                                  >
                                </template>
                              </v-autocomplete>
                            </v-flex>
                            <v-flex lg2 pb-3>
                              <v-btn
                                dark
                                style="background-color: red"
                                dense
                                @click="removeMember(i)"
                              >
                                Remove
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-layout>

                  <v-layout wrap justify-start style="background-color: white">
                    <v-card flat>
                      <v-layout wrap>
                        <v-flex
                          pt-1
                          v-for="(item, i) in memberDetails"
                          :key="i"
                        >
                          <v-layout wrap pt-2>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                v-model="item.memberName"
                                dense
                                outlined
                                label="memberName"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Member Name<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-text-field
                                color="black"
                                v-model="item.memberDob"
                                dense
                                outlined
                                label="memberDob"
                                hide-details
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    DOB ( DD-MM-YYYY )<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                              <!-- <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field clearable dense outlined readonly v-model="item.memberDob" v-bind="attrs"
                                    background-color="white" v-on="on" @click:clear="memberDob = null"
                                    style="color: black">
                                    <template v-slot:append>
                                      <v-icon color="black">mdi-chevron-down</v-icon>
                                    </template>
                                    <template v-slot:label>
                                      <span class="custom-label-text" style="color: black; font-size: 14px">DOB</span>
                                    </template>
                                  </v-text-field>
                                </template>
                                <v-date-picker v-model="item.memberDob" color="#13736f"
                                  @change="menu2 = false"></v-date-picker>
                              </v-menu> -->
                            </v-flex>
                            <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-autocomplete
                                outlined
                                dense
                                color="black"
                                :items="gender"
                                v-model="item.memberGender"
                                item-text="name"
                                item-value="name"
                                item-color="#FF1313"
                                hide-details
                                clearable
                                class="custom-autocomplete mainfont"
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Gender<span
                                      style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                                <template v-slot:append>
                                  <v-icon color="black"
                                    >mdi-chevron-down</v-icon
                                  >
                                </template>
                              </v-autocomplete>
                            </v-flex>
                            <!-- <v-flex lg3 pt-8 pt-lg-0 pa-3>
                              <v-autocomplete
                                outlined
                                dense
                                color="black"
                                :items="foodpre"
                                v-model="item.foodPreference"
                                item-text="name"
                                item-value="name"
                                item-color="#FF1313"
                                hide-details
                                clearable
                                class="custom-autocomplete mainfont"
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                  >
                                    Food Preferences
                                    <span style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                                <template v-slot:append>
                                  <v-icon color="black"
                                    >mdi-chevron-down</v-icon
                                  >
                                </template>
                              </v-autocomplete>
                            </v-flex> -->
                            <v-flex lg2 pb-3>
                              <v-btn
                                dark
                                style="background-color: #68d389"
                                dense
                                @click="removeVehicle(i)"
                              >
                                Remove
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs4 pl-3>
                      <v-text-field
                        dense
                        color="#283e51"
                        outlined
                        background-color="white"
                        v-model="list.maleCount"
                        disabled
                      >
                        <template v-slot:label>
                          <span
                            class="custom-label-text"
                            style="color: black; font-size: 14px"
                            >No. Of Male Participants
                            <span style="color: red; font-size: 20px">*</span>
                          </span>
                        </template>
                      </v-text-field>
                    </v-flex>
                    <v-flex xs4 pl-3>
                      <v-text-field
                        dense
                        color="#283e51"
                        outlined
                        background-color="white"
                        v-model="list.femaleCount"
                        disabled
                      >
                        <template v-slot:label>
                          <span
                            class="custom-label-text"
                            style="color: black; font-size: 14px"
                            >No. Of Female Participants
                            <span style="color: red; font-size: 20px">*</span>
                          </span>
                        </template>
                      </v-text-field>
                    </v-flex>
                    <v-flex xs4 pl-3 pr-3>
                      <v-text-field
                        dense
                        color="#283e51"
                        outlined
                        background-color="white"
                        v-model="list.transCount"
                        disabled
                      >
                        <template v-slot:label>
                          <span
                            class="custom-label-text"
                            style="color: black; font-size: 14px"
                            >No. Of Transgender Participants
                            <span style="color: red; font-size: 20px">*</span>
                          </span>
                        </template>
                      </v-text-field>
                    </v-flex>
                    <!-- <v-flex xs4 pl-3>
    <v-text-field dense color="#283e51" outlined background-color="white" :value="maleCount2" disabled>
      <template v-slot:label>
        <span class="custom-label-text" style="color: black; font-size: 14px">Male Count
          <span style="color: red; font-size: 20px">*</span>
        </span>
      </template>
    </v-text-field>
  </v-flex>
  <v-flex xs4 pl-3>
    <v-text-field dense color="#283e51" outlined background-color="white" :value="femaleCount2" disabled>
      <template v-slot:label>
        <span class="custom-label-text" style="color: black; font-size: 14px">Female Count
          <span style="color: red; font-size: 20px">*</span>
        </span>
      </template>
    </v-text-field>
  </v-flex>
  <v-flex xs4 pl-3 pr-3>
    <v-text-field dense color="#283e51" outlined background-color="white" :value="transCount2" disabled>
      <template v-slot:label>
        <span class="custom-label-text" style="color: black; font-size: 14px">Transgender Count
          <span style="color: red; font-size: 20px">*</span>
        </span>
      </template>
    </v-text-field>
  </v-flex> -->
                  </v-layout>
                </div>
              </template>
            </v-card>
          </v-flex>
          <v-flex pa-4 xs12 text-center>
            <v-card>
              <v-layout wrap>
                <v-flex xs12 text-center>
                  <v-card>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <template>
                          <div>
                            <v-layout wrap justify-center>
                              <v-flex
                                pl-4
                                pt-2
                                pb-2
                                xs12
                                text-left
                                style="background-color: black"
                              >
                                <span style="font-size: 20px; color: white">
                                  Scanned Documents
                                </span>
                              </v-flex>
                              <v-flex xs12 text-center pa-4>
                                <span style="font-size: 20px; color: black">
                                  (Documents must be PDF format. File size
                                  cannot exceed 100 KB)
                                </span>
                              </v-flex>
                              <v-flex xs11 pb-6>
                                <v-layout wrap justify-center>
                                  <v-flex xs6 text-left v-if="list.applicantCategory === 'Students'">
                                    <label for="idProof">
                                      <span
                                        >ID proof of Head of institution /
                                        Organisation
                                        <span
                                          style="color: red; font-size: 23px"
                                          >*</span
                                        >
                                      </span>
                                    </label>
                                  </v-flex>
                                  <v-flex xs2 pt-2 v-if="idProof && list.applicantCategory === 'Students'">
                                    <v-btn
                                      color="#3CB043"
                                      dark
                                      download
                                      :href="mediaURL + idProof"
                                      target="_blank"
                                      ><span
                                        style="
                                          text-transform: none;
                                          font-family: mainfont;
                                          font-size: 12px;
                                        "
                                        >View Pdf</span
                                      ></v-btn
                                    >
                                  </v-flex>
                                  <v-flex xs4 pt-3 v-if="list.applicantCategory === 'Students'">
                                    <input
                                      type="file"
                                      id="idProof"
                                      ref="idProof"
                                      accept=".pdf"
                                      @change="handleFileChange('idProof')"
                                    />
                                  </v-flex>

                                  <v-flex xs6 pt-9 text-left>
                                    <label for="reqLetter" text-left>
                                      <span
                                        >Request Letter from Head of
                                        Institution/ Organisation
                                        <span
                                          style="color: red; font-size: 23px"
                                          >*</span
                                        >
                                      </span>
                                    </label>
                                  </v-flex>
                                  <v-flex xs2 pt-9>
                                    <v-btn
                                      color="#3CB043"
                                      dark
                                      download
                                      :href="mediaURL + reqLetter"
                                      target="_blank"
                                      ><span
                                        style="
                                          text-transform: none;
                                          font-family: mainfont;
                                          font-size: 12px;
                                        "
                                        >View Pdf</span
                                      ></v-btn
                                    >
                                  </v-flex>
                                  <v-flex xs4 pt-9>
                                    <input
                                      type="file"
                                      id="reqLetter"
                                      ref="reqLetter"
                                      accept=".pdf"
                                      @change="handleFileChange('reqLetter')"
                                    />
                                  </v-flex>

                                  <v-flex xs6 pt-9 text-left>
                                    <label for="participantfile" text-left>
                                      <span
                                        >List of participants duly signed by
                                        Head of Institution/ Organisation
                                        <span
                                          style="color: red; font-size: 23px"
                                          >*</span
                                        >
                                      </span>
                                    </label>
                                  </v-flex>
                                  <v-flex xs2 pt-9>
                                    <v-btn
                                      color="#3CB043"
                                      dark
                                      download
                                      :href="mediaURL + participantfile"
                                      target="_blank"
                                      ><span
                                        style="
                                          text-transform: none;
                                          font-family: mainfont;
                                          font-size: 12px;
                                        "
                                        >View Pdf</span
                                      ></v-btn
                                    >
                                  </v-flex>
                                  <v-flex xs4 pt-10>
                                    <input
                                      type="file"
                                      id="participantfile"
                                      ref="participantfile"
                                      accept=".pdf"
                                      @change="
                                        handleFileChange('participantfile')
                                      "
                                    />
                                  </v-flex>

                                  <v-flex xs6 pt-9 text-left>
                                    <label for="leaderIdProof" text-left>
                                      <span
                                        >ID Proof of one of the Group
                                        Leader/Teaching staff
                                        <span
                                          style="color: red; font-size: 23px"
                                          >*</span
                                        >
                                      </span>
                                    </label>
                                  </v-flex>
                                  <v-flex xs2 pt-9>
                                    <v-btn
                                      color="#3CB043"
                                      dark
                                      download
                                      :href="mediaURL + leaderIdProof"
                                      target="_blank"
                                      ><span
                                        style="
                                          text-transform: none;
                                          font-family: mainfont;
                                          font-size: 12px;
                                        "
                                        >View Pdf</span
                                      ></v-btn
                                    >
                                  </v-flex>

                                  <v-flex xs4 pt-10>
                                    <input
                                      type="file"
                                      id="leaderIdProof"
                                      ref="leaderIdProof"
                                      accept=".pdf"
                                      @change="
                                        handleFileChange('leaderIdProof')
                                      "
                                    />
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                        </template>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex pa-4 xs12 text-center>
            <v-card>
              <v-layout wrap justify-center>
                <v-flex
                  pl-4
                  pt-2
                  pb-2
                  xs12
                  text-left
                  style="background-color: black"
                >
                  <span style="font-size: 20px; color: white">
                    Undertaking
                  </span>
                </v-flex>
                <v-flex pt-3 xs1>
                  <v-checkbox v-model="isChecked"></v-checkbox>
                </v-flex>

                <v-flex xs10 pb-5 text-left>
                  <v-layout wrap pt-4>
                    <v-flex
                      xs12
                      pt-4
                      v-for="(content, index) in agreementContent"
                      :key="index"
                    >
                      <span>{{ content }}</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs9 pb-10>
                  <v-layout wrap justify-start>
                    <v-flex xs4>
                      <v-text-field
                        type="text"
                        dense
                        disabled
                        color="#283e51"
                        outlined
                        background-color="white"
                        v-model="list.applicantname"
                        hide-details
                      >
                        <template v-slot:label>
                          <span
                            class="custom-label-text"
                            style="color: black; font-size: 13px"
                          >
                            Name of the applicant
                            <span style="color: red; font-size: 20px">*</span>
                          </span>
                        </template>
                      </v-text-field>
                    </v-flex>
                    <v-flex xs4 pl-2>
                      <v-text-field
                        type="text"
                        dense
                        disabled
                        color="#283e51"
                        outlined
                        background-color="white"
                        v-model="totalAmount"
                        hide-details
                      >
                        <template v-slot:label>
                          <span
                            class="custom-label-text"
                            style="color: black; font-size: 13px"
                          >
                            Amount to be paid
                            <span style="color: red; font-size: 20px">*</span>
                          </span>
                        </template>
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-10>
        <v-layout wrap justify-end>
          <v-flex xs2 pr-4>
            <v-btn
              color="black"
              block
              @click="editApplication()"
              :disabled="!isChecked"
            >
              <span style="color: white"> SAVE </span>
            </v-btn>
          </v-flex>
          <v-flex xs2 pr-4>
            <v-btn @click="getPreview()" color="black" block>
              <span style="color: white">PREVIEW</span
              ><v-icon color="white"> mdi-download </v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs2 pr-4 v-if="isSaved">
            <v-btn
              @click="submitdialog = true"
              color="black"
              block
              type="submit"
              :disabled="!isChecked"
            >
              <span style="color: white">SUBMIT</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-dialog
        :retain-focus="true"
        persistent
        v-model="submitdialog"
        max-width="800px"
      >
        <v-card>
          <v-card-title
            class="mainfont"
            style="color: black; font-size: 18px; font-weight: lighter"
          >
            Please enter the details below and proceed for
            payment.</v-card-title
          >
          <v-layout wrap justify-center>
            <v-flex xs10>
              <v-layout wrap justify-start>
                <v-flex xs12>
                  <v-layout wrap justify-start>
                    <v-flex xs12 lg6>
                      <v-text-field
                        type="text"
                        dense
                        clearable
                        outlined
                        class="inputPrice"
                        v-model="paymentname"
                        :rules="[(v) => !!v || 'Name is required']"
                      >
                        <template v-slot:label>
                          <span
                            class="custom-label-text"
                            style="color: black; font-size: 14px"
                          >
                            Name
                            <span style="color: red; font-size: 20px">*</span>
                          </span>
                        </template>
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg6 pl-2>
                      <v-text-field
                        type="text"
                        dense
                        clearable
                        outlined
                        class="inputPrice"
                        v-model="paymentaddress"
                        :rules="[(v) => !!v || 'Address is required']"
                      >
                        <template v-slot:label>
                          <span
                            class="custom-label-text"
                            style="color: black; font-size: 14px"
                          >
                            Address
                            <span style="color: red; font-size: 20px">*</span>
                          </span>
                        </template>
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-start>
                    <v-flex xs12 lg6>
                      <v-text-field
                        type="number"
                        dense
                        clearable
                        outlined
                        class="inputPrice"
                        v-model="paymentphonenumber"
                        :rules="[
                          (v) => !!v || 'Phone number is required',
                          (v) =>
                            /^[0-9]{10}$/.test(v) ||
                            'Phone number must have 10 digits',
                        ]"
                      >
                        <template v-slot:label>
                          <span
                            class="custom-label-text"
                            style="color: black; font-size: 14px"
                          >
                            Phone Number
                            <span style="color: red; font-size: 20px">*</span>
                          </span>
                        </template>
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg6 pl-2>
                      <v-text-field
                        type="number"
                        dense
                        clearable
                        outlined
                        class="inputPrice"
                        v-model="paymentpincode"
                        :rules="[
                          (v) => !!v || 'Pincode is required',
                          (v) =>
                            /^[0-9]{6}$/.test(v) ||
                            'Pincode must have 6 digits',
                        ]"
                      >
                        <template v-slot:label>
                          <span
                            class="custom-label-text"
                            style="color: black; font-size: 14px"
                          >
                            Pincode
                            <span style="color: red; font-size: 20px">*</span>
                          </span>
                        </template>
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-start>
                    <v-flex xs12 lg6>
                      <v-text-field
                        type="text"
                        dense
                        clearable
                        outlined
                        class="inputPrice"
                        v-model="paymentpancardnumber"
                        :rules="[
                          (v) => !!v || 'PAN Card Number is required',
                          (v) =>
                            /^[A-Za-z0-9]{10}$/.test(v) ||
                            'PAN Card Number must have 10 characters',
                        ]"
                      >
                        <template v-slot:label>
                          <span
                            class="custom-label-text"
                            style="color: black; font-size: 14px"
                          >
                            PAN Card Number
                            <span style="color: red; font-size: 20px">*</span>
                          </span>
                        </template>
                      </v-text-field>
                    </v-flex>
                    <!-- <v-flex xs12 lg6 pl-2>
                      <v-text-field
                        type="number"
                        dense
                        clearable
                        outlined
                        class="inputPrice"
                        v-model="paymenttaxid"
                        :rules="[(v) => !!v || 'TAX Id is required']"
                      >
                        <template v-slot:label>
                          <span
                            class="custom-label-text"
                            style="color: black; font-size: 14px"
                          >
                            TAX Id
                            <span style="color: red; font-size: 20px">*</span>
                          </span>
                        </template>
                      </v-text-field>
                    </v-flex> -->
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="paymentdialog()" class="mainfont"
              >Cancel</v-btn
            >
            <v-btn
              color="green"
              class="mainfont"
              text
              @click="submit()"
              :disabled="submitted"
            >
              {{ submitted ? "Processing..." : "Proceed For Payment" }}</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        :retain-focus="true"
        persistent
        v-model="memberdialog"
        max-width="350px"
      >
        <v-card>
          <v-layout wrap justify-center>
            <v-flex xs10 pt-4 text-center>
              <span
                class="mainfont"
                style="color: black; font-size: 18px; font-weight: lighter"
              >
                No slots available on {{ cdate }}
              </span>
            </v-flex>
            <v-flex text-center>
              <v-btn color="green" class="mainfont" text @click="submit2()"
                >OK</v-btn
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>
  
<script>
import axios from "axios";
import moment from "moment";
// import CryptoJS from "crypto-js";

export default {
  components: {},
  data() {
    const today = new Date().toISOString().substr(0, 10);
    return {
      idProof: {},
      isSaved: false,
      reqLetter: {},
      dist: this.$route.query.district,
      participantfile: {},
      leaderIdProof: {},
      cdate: {},
      gender: ["Male", "Female", "Transgender"],
      foodpre: ["Vegetarian", "Non-vegetarian"],
      memberDetails: [],
      memberDetails2: [],
      memberdialog: false,
      leaderName: null,
      memberGender: ["Male", "Female", "Transgender"],
      leaderAddress1: null,
      leaderAddress2: null,
      telephone: null,
      idCardType: null,
      paymentphonenumber: "",
      paymentpancardnumber: "",
      paymentname: "",
      paymenttaxid: "",
      paymentpincode: "",
      paymentaddress: "",
      leaderPhone: null,
      leaderGender: null,
      leaderEmail: null,
      district: null,
      instHeadName: null,
      list2: {},
      genderCounts2: {
        male: 0,
        female: 0,
        transgender: 0,
      },
      headDesignation: null,
      idCardTypeidCardType: null,
      idNo: null,
      idcardtypes: ["Election Id Card", "Driving License", "Aadhaar Card"],
      instName: null,
      instCode: null,
      instType: null,
      instAddress: null,
      instTeleNo: null,
      instMobileNo: null,
      instEmail: null,
      institutionTypes: ["Government", "Aided", "Private", "Others"],
      startDate: today,
      showSnackBar: false,
      isChecked: false,
      appLoading: false,
      msg: "",
      expandSection: false,
      name: null,
      barlist: [],
      campd: null,
      agreementContent: [
        "I, hereby undertake on behalf of the Institution/Organization/Team that myself and all the Team members taking part in the Nature Camp shall abide by the conditions as per the existing laws pertaining to Forests & Wildlife, orders of the Forest Officers/competent authorities during the Nature Camp and maintain discipline throughout the course of the Nature Camp.",
        "I, hereby declare that the entries made by me, for the members of the team, in the Application Form are complete and true to the best of my knowledge, belief, and information.",
        "I, hereby undertake to present the original documents/ID proof (of Group leader) for verification immediately upon demand by the competent authorities, as and when required.",
        "I, hereby declare that I and or my team/group members shall be solely responsible for our involvement in any kind of unlawful activities whether inside or outside the premises at the selected location of the Nature Camp, and shall be liable for punishment, if any, as per the law of the land.",
        "I further declare that we are aware that the permission given to me and or my team may be canceled, at any stage, if I and or my team members are found ineligible and/or the information provided by me are found to be incorrect.",
        "I declare that my team members and I will strictly adhere to the code of conduct, as issued by the Competent Authority for the Nature Camp Programme at the selected location.",
        "I declare that I am aware of the fact that, even if a Nature Camp is allotted at selected location to me and or my team, the Camp could be canceled at any stage, by the Divisional Forest Officer/Wildlife Warden or any Competent Authority and I have no objection and I and or my team members and or my Head of Institution/Organisation will not raise any dispute against this.",
      ],

      list: {},
      selectedDistrict: null,
      districts: [
        "Thiruvananthapuram",
        "Kollam",
        "Pathanamthitta",
        "Alappuzha",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Thrissur",
        "Palakkad",
        "Malappuram",
        "Kozhikode",
        "Wayanad",
        "Kannur",
        "Kasaragod",
      ],
      campview: "",
      amounttobepaid: 0,
      campDuration: "",
      menu1: false,
      submitdialog: false,
      submitted: false,
      male: null,
      leaderDetails2: [],
      memberCount: null,
      getdetailsdata: "",
      leaderDetails: [],
      listt: {
        maleCount: 0,
        femaleCount: 0,
        transCount: 0,
      },
      menu2: false,
      applicantCategory: "",
      studentCategory: "",
      applicantname: null,
      applicantcategorys: [
        "Students",
        "Media Personnel",
        "Corporation Councilors,Muncipal Councilors,Jilla Panchayat Members,Block Panchayat Members And Grama Panchayt Members",
        "Members Of Charitable, Non-governmental and Youth Organizations",
      ],
      studentCategories: [
        "UP students",
        "HS students",
        "HSS students",
        "College students",
      ],
    };
  },
  watch: {
    totalParticipants() {
      this.checkAvailability2();
    },
  },
  // created() {
  //   const encryptedDistrict = this.$route.query.district;
  //   const decryptedDistrict = CryptoJS.AES.decrypt(encryptedDistrict, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
  //   this.dist = decryptedDistrict;
  // },
  computed: {
    maleCount2() {
      return this.memberDetails2.filter((item) => item.memberGender === "Male")
        .length;
    },
    totalAmount() {
      return this.list.campDuration * this.amounttobepaid;
    },

    femaleCount2() {
      return this.memberDetails2.filter(
        (item) => item.memberGender === "Female"
      ).length;
    },

    transCount2() {
      return this.memberDetails2.filter(
        (item) => item.memberGender === "Transgender"
      ).length;
    },

    maleCount() {
      return this.memberDetails.filter((item) => item.memberGender === "Male")
        .length;
    },

    femaleCount() {
      return this.memberDetails.filter((item) => item.memberGender === "Female")
        .length;
    },

    transCount() {
      return this.memberDetails.filter(
        (item) => item.memberGender === "Transgender"
      ).length;
    },

    calculatedGenderCounts2() {
      const counts = { male: 0, female: 0, transgender: 0 };

      this.memberDetails2.forEach((item) => {
        const selectedGender = item.memberGender;
        if (selectedGender === "Male") {
          counts.male++;
        } else if (selectedGender === "Female") {
          counts.female++;
        } else if (selectedGender === "Transgender") {
          counts.transgender++;
        }
      });

      return counts;
    },
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      return `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;
    },
    PhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    totalParticipants: {
      get() {
        return (
          parseInt(this.maleCount) +
          parseInt(this.femaleCount) +
          parseInt(this.transCount)
        );
      },
      set() {
        // You can handle the case where the user tries to edit the totalParticipants if needed
        console.log("Total Participants cannot be edited directly.");
      },
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
  },
  mounted() {
    this.getList();
    // this.checkAvailability();
  },

  methods: {
    filterNonNumericInput() {
      this.list.instHeadName = this.list.instHeadName.replace(/[0-9]/g, "");
      this.list.headDesignation = this.list.headDesignation.replace(
        /[0-9]/g,
        ""
      );
    },
    paymentdialog() {
      this.submitdialog = false;
      this.submitted = false;
      this.paymentphonenumber = "";
      this.paymentname = "";
      this.paymentpancardnumber = "";
      this.paymentaddress = "";
      this.paymentpincode = "";
      this.paymenttaxid = "";
    },
    getPreview() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/application/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.$route.query.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "preview.pdf");
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading invoice:", error);
        });
    },
    submit2() {
      this.cdate = null;
      this.memberdialog = false;
      this.memberCount = "";
      this.totalParticipants = "";
      this.maleCount = "";
      this.femaleCount = "";
      this.transCount = "";
    },
    // handleFileChange(field) {
    //   this[field] = this.$refs[field].files[0];
    // },
    handleFileChange(field) {
      const file = this.$refs[field].files[0];

      if (!this.allowedFileTypes.includes(file.type)) {
        alert("Only PDF and image files (JPEG, PNG) are allowed.");
        this.$refs[field].value = null;
        return;
      }

      const maxSize = 100 * 1024;
      if (file.size > maxSize) {
        alert("File size cannot exceed 100 KB.");
        this.$refs[field].value = null;
        return;
      }
      this[field] = file;
    },
    validateFile(file) {
      return file.size <= 100 * 1024;
    },
    removeMember(index) {
      this.memberDetails2.splice(index, 1);
    },
    removeLeader(index) {
      this.leaderDetails2.splice(index, 1);
    },
    removeLeaderDetail(index) {
      this.leaderDetails2.splice(index, 1);
    },
    checkAvailability(cdate) {
      axios({
        method: "POST",
        url: "/check/availability",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          date: cdate,
          campid: this.campidx,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.list2 = response.data.natureCampDetails;
            this.checkstatus = response.data.data;

            // Check if checkstatus is equal to 0
            if (this.checkstatus === 0) {
              this.memberdialog = true;
            }

            console.log("message is ", this.checkstatus);
          } else {
            this.memberdialog = true;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },

    addLeaderDetails() {
      this.leaderDetails.push({
        leaderName: "",
        leaderGender: "",
        leaderAddress1: "",
        leaderAddress2: "",
        telephone: "",
        leaderPhone: "",
        leaderEmail: "",
      });
      this.leaderGender = "";
      this.leaderName = "";
      this.leaderAddress1 = "";
      this.leaderAddress2 = "";
      this.telephone = "";
      this.leaderPhone = "";
      this.leaderEmail = "";
    },
    removeValues() {
      this.leaderDetails.pop();
    },
    removeleaderdetails(index) {
      this.leaderDetails.splice(index, 1);
    },
    submit() {
      if (!this.paymentname) {
        this.msg = "Provide Name For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentaddress) {
        this.msg = "Provide Address For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentphonenumber) {
        this.msg = "Provide Phone Number For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentpincode) {
        this.msg = "Provide Pincode For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentpancardnumber) {
        this.msg = "Provide PAN Card Number For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      // if (!this.paymenttaxid) {
      //   this.msg = "Provide Tax Id For Payment Procedure.";
      //   this.showSnackBar = true;
      //   return;
      // }
      if (!/^[0-9]{10}$/.test(this.paymentphonenumber)) {
        this.msg = "Phone Number must be 10 digits.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[0-9]{6}$/.test(this.paymentpincode)) {
        this.msg = "Pincode must be 6 digits.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[A-Za-z0-9]{10}$/.test(this.paymentpancardnumber)) {
        this.msg =
          "PAN Card Number must have exactly 10 characters.";
        this.showSnackBar = true;
        return;
      }
      this.submitted = true;
      axios({
        method: "POST",
        url: "/final/submit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.$route.query.id,
          amount: this.totalAmount,
          address: this.paymentaddress,
          mobnumber: this.paymentphonenumber,
          pin_no: this.paymentpincode,
          pan_no: this.paymentpancardnumber,
          tax_id: this.paymenttaxid,
          party_name: this.paymentname,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.submitdialog = false;
            this.getdetailsdata = response.data.data;
            this.openFormInNewWindow();
            this.$router.push({ path: "/mybooking" });
            // window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.submitted = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
          this.submitted = false;
        });
    },
    openFormInNewWindow() {
      // Create a form element
      const form = document.createElement("form");
      form.method = "POST";
      form.action =
        "https://etreasury.kerala.gov.in/api/eTreasury/service/ChallanGeneration.php"; // Replace with your actual endpoint URL
      form.target = "_blank"; // Open in a new window or tab

      // Create hidden input fields for 'input_data' and 'input_headers'
      const inputDataField = document.createElement("input");
      inputDataField.type = "hidden";
      inputDataField.name = "input_data";
      inputDataField.value = JSON.stringify({
        HMAC: this.getdetailsdata.HMAC, // Replace with actual HMAC value

        DATA: this.getdetailsdata.DATA,

        // Replace with actual encrypted data
      });

      const inputHeadersField = document.createElement("input");
      inputHeadersField.type = "hidden";
      inputHeadersField.name = "input_headers";
      inputHeadersField.value = JSON.stringify({
        clientId: this.getdetailsdata.clientId,
        authToken: this.getdetailsdata.authToken,
        // Replace with actual auth token
      });

      // Append hidden inputs to the form
      form.appendChild(inputDataField);
      form.appendChild(inputHeadersField);

      // Append form to the body (required to submit it)
      document.body.appendChild(form);

      // Submit the form
      form.submit();

      // Remove the form from the DOM after submission
      document.body.removeChild(form);
    },
    addValues() {
      this.memberDetails.push({
        memberGender: "",
        memberDob: "",
        memberName: "",
        foodPreference: "",
      });
    },
    removeVehicle(index) {
      this.memberDetails.splice(index, 1);
    },
    editApplication() {
      this.memberDetails.forEach((item) => {
        this.memberDetails2.push({
          memberGender: item.memberGender,
          memberDob: item.memberDob,
          memberName: item.memberName,
          foodPreference: item.foodPreference,
        });
      });
      this.leaderDetails.forEach((item) => {
        this.leaderDetails2.push({
          leaderName: item.leaderName,
          leaderGender: item.leaderGender,
          leaderAddress1: item.leaderAddress1,
          leaderAddress2: item.leaderAddress2,
          telephone: item.telephone,
          leaderPhone: item.leaderPhone,
          leaderEmail: item.leaderEmail,
        });
      });
      const formData = new FormData();
      formData.append("idProof", this.idProof);
      formData.append("reqLetter", this.reqLetter);
      formData.append("participantfile", this.participantfile);
      formData.append("leaderIdProof", this.leaderIdProof);
      formData.append("leaderDetails", JSON.stringify(this.list.leaderDetails));
      formData.append("memberDetails", JSON.stringify(this.list.memberDetails));
      formData.append("instHeadName", this.list.instHeadName);
      formData.append("headDesignation", this.list.headDesignation);
      formData.append("idCardType", this.list.idCardType);
      formData.append("idNo", this.list.idNo);
      formData.append("appid", this.$route.query.id);
      formData.append("instName", this.list.instName);
      formData.append("instCode", this.list.instCode);
      formData.append("instType", this.list.instType);
      formData.append("instAddress", this.list.instAddress);
      formData.append("instTeleNo", this.list.instTeleNo);
      formData.append("instMobileNo", this.list.instMobileNo);
      formData.append("instEmail", this.list.instEmail);
      formData.append("givendate", this.cdate);
      formData.append("district", this.dist);
      formData.append("campDuration", this.list.campDuration);
      // formData.append("memberCount", this.list.memberCount);
      // formData.append("maleCount", this.list.maleCount);
      // formData.append("femaleCount", this.list.femaleCount);
      // formData.append("transCount", this.list.transCount);
      formData.append("applicantCategory", this.list.applicantCategory);
      formData.append("studentCategory", this.studentCategory);
      formData.append("applicantname", this.list.applicantname);
      axios({
        method: "POST",
        url: "/edit/application",
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
        data: formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            // window.location.reload();
            this.isSaved = true;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    getList() {
      axios({
        method: "POST",
        url: "/single/application",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.list = response.data.data;
            this.campidx = response.data.data.campid._id;
            // console.log("cece", this.campidx);
            this.campviewdata();
            this.campd = this.list.campDuration;
            this.list.memberDetails.forEach((member) => {
              member.memberDob = moment(member.memberDob).format("YYYY-MM-DD");
            });
            this.leaderDetails2 = this.list.leaderDetails;
            this.idProof = this.list.idProof;
            this.reqLetter = this.list.reqLetter;
            this.participantfile = this.list.participantfile;
            this.memberDetails2 = this.list.memberDetails;
            this.leaderDetails2 = this.list.leaderDetails;
            this.leaderIdProof = this.list.leaderIdProof;
            this.cdate = this.list.startDate;
            this.cdate = moment(this.cdate).format("YYYY-MM-DD");
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    campviewdata() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/view/camp",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          campid: this.campidx,
        },
      })
        .then((response) => {
          this.campview = response.data.data;
          this.amounttobepaid = this.campview.rate;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.no-spinners {
  /* Remove the up and down arrows */
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>