<template>
  <div>
    <v-app id="inspire">
      <v-img height="100vh" src="../../assets/Images/NATURESTAY.png">
        <v-layout wrap justify-center fill-height>
          <v-flex xs12 align-self-center>
            <v-snackbar
              v-model="showSnackBar"
              color="black"
              right
              :timeout="timeout"
            >
              <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">
                  <span style="color: white">{{ msg }}</span>
                </v-flex>
                <v-flex text-right>
                  <v-btn
                    small
                    :ripple="false"
                    text
                    @click="showSnackBar = false"
                  >
                    <v-icon style="color: white">mdi-close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-snackbar>
            <v-layout wrap justify-end>
              <v-flex xs7>
                <v-layout wrap justify-center>
                  <v-flex xs7>
                    <v-card>
                      <v-layout wrap justify-center>
                        <v-flex xs3 pt-5>
                          <v-img
                            height="60px"
                            contain
                            src="../../assets/Images/blacklogo.png"
                          ></v-img>
                        </v-flex>
                        <v-flex xs10 text-center pt-2 class="mainfont">
                          <span style="font-size: 22px">Find Your Account</span>
                        </v-flex>
                        <v-flex xs10 pt-6 v-if="!otpFieldVisible">
                          <v-text-field
                            color="black"
                            outlined
                            background-color="white"
                            dense
                            label="Phone Number"
                            type="number"
                            class="inputPrice"
                            v-model="mobNumber"
                            hide-details
                            :rules="guestPhoneRules"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs10 pt-6 v-if="otpFieldVisible">
                          <v-text-field
                            color="black"
                            outlined
                            background-color="white"
                            dense
                            label="OTP"
                            type="number"
                            class="inputPrice"
                            v-model="otp"
                            hide-details
                          >
                          </v-text-field>
                          <v-flex xs10 pt-6 v-if="otpFieldVisible">
                            <v-text-field
                              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                              color="black"
                              outlined
                              background-color="white"
                              dense
                              label="Password"
                              :type="show3 ? 'text' : 'password'"
                              class="inputPrice"
                              v-model="password"
                              hide-details
                              @click:append="show3 = !show3"
                            >
                            </v-text-field>
                          </v-flex>
                          <v-flex xs10 pt-6 v-if="otpFieldVisible">
                            <v-text-field
                              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                              color="black"
                              outlined
                              background-color="white"
                              dense
                              label="Confirm Password"
                              :type="show3 ? 'text' : 'password'"
                              class="inputPrice"
                              v-model="confirmPassword"
                              hide-details
                              @click:append="show3 = !show3"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-flex>
                        <v-flex pt-6 xs10>
                          <v-btn
                            class="no-uppercase mainfont"
                            dark
                            block
                            color="rgba(41, 119, 170, 1)"
                            @click="submit"
                          >
                            <span>{{
                              otpFieldVisible ? "Submit" : "Get OTP"
                            }}</span>
                            <v-icon color="white">mdi-arrow-right</v-icon>
                          </v-btn>
                        </v-flex>
                        <v-flex
                          @click="$router.push('/')"
                          style="cursor: pointer"
                          pb-8
                          class="mainfont"
                          text-center
                          pt-3
                          xs10
                        >
                          <span>Already a user? click here to Login</span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-img>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  data() {
    return {
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      mobNumber: null,
      otp: null,
      password: null,
      confirmPassword: null,
      otpFieldVisible: false,
      userId: "",
      show1: false,
      show2: true,
      show3: false,
      show4: false,
    };
  },
  computed: {
    guestPhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^\d{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
  },
  methods: {
    submit() {
      if (!this.mobNumber) {
        this.msg = "Please enter phone number";
        this.showSnackBar = true;
      } else if (!/^\d{10}$/.test(this.mobNumber)) {
        this.msg = "Phone number must be 10 digits";
        this.showSnackBar = true;
      } else {
        if (this.otpFieldVisible) {
          if (this.validateInput()) {
            this.register();
          }
        } else {
          this.sendMobileNumber();
        }
      }
    },

    validateInput() {
      if (!this.otp) {
        this.msg = "Please enter OTP";
        this.showSnackBar = true;
        return false;
      } else if (!this.password) {
        this.msg = "Please enter password";
        this.showSnackBar = true;
        return false;
      } else if (this.password !== this.confirmPassword) {
        this.msg = "Passwords do not match";
        this.showSnackBar = true;
        return false;
      }
      return true;
    },
    register() {
      const encryptedOtp = CryptoJS.AES.encrypt(this.otp, 'natureOadtp0:"?>}|irgwwvn[!@#$%^&*()camp').toString();
      const encryptedPassword = CryptoJS.AES.encrypt( this.password, 'natPassur*^@!~(&%eadirgwwvn[!@#$%^&*()camp').toString();
      const encryptedUserid = CryptoJS.AES.encrypt(this.userId, 'natureadi{}:">#)(&$#!rgwwvn[!@#$%^&*()camp').toString();

      axios({
        method: "POST",
        url: "/forgotpassword/verify-otp",
        data: {
          otp: encryptedOtp,
          password: encryptedPassword,
          userid: encryptedUserid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            setTimeout(() => {
              this.$router.push({ path: "/" });
            }, 3000);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sendMobileNumber() {
      const encryptedPhone = CryptoJS.AES.encrypt(
        this.mobNumber,
        "natureadirgwwvnforgot!@#$%^&*()[!@#$%^&*()camp"
      ).toString();

      axios({
        method: "POST",
        url: "/forgot/password",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // mobNumber: this.mobNumber,
          mobNumber: encryptedPhone,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.userId = response.data.data._id;
            this.otpFieldVisible = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
  },
};
</script>

<style>
.no-spinners {
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
