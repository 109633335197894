<template>
    <div>
        <v-layout pb-6 pr-6 pl-6 class="mainfont" wrap justify-center>
            <v-flex xs12 pt-4>
                <v-layout wrap justify-center>
                    <v-snackbar v-model="showSnackBar" color="black">
                        <v-layout wrap justify-center>
                            <v-flex text-left class="align-self-center">
                                <span style="color: white">
                                    {{ msg }}
                                </span>
                            </v-flex>
                            <v-flex text-right>
                                <v-btn small :ripple="false" text @click="showSnackBar = false">
                                    <v-icon style="color: white">mdi-close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-snackbar>
                    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF"
                        color="#42275a" spinner="spinner" />
                </v-layout>
                <v-layout wrap justify-start>
                    <v-flex xs11 lg8 pt-8 text-left>
                        <span style="font-size: 25px; font-weight: bolder">
                            RESERVED DATES
                        </span>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 pt-4>
                <v-card>
                    <v-layout wrap>
                        <v-flex xs12 pt-4>
                            <v-layout wrap justify-start>
                                <v-flex xs6>
                                    <v-layout wrap justify-end>
                                        <v-flex xs4 text-center><span>FROM DATE</span></v-flex>
                                        <v-flex xs4 text-center><span>TO DATE</span></v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 pt-1 pb-1> <v-divider></v-divider></v-flex>
                        <v-flex xs12 pt-4 v-for="(item, i) in list" :key="i">
                            <v-layout wrap justify-center style="font-size: 14px" pa-1>
                                <v-flex xs2 text-center>
                                    <span v-if="item.fromDate">
                                        {{ formatDate(item.fromDate) }} <br />
                                        {{ formatForwardDate(item.toDate) }}
                                    </span>
                                    <span v-else> Nill </span>
                                </v-flex>
                                <v-flex xs2 text-center>
                                    <span v-if="item.toDate">
                                        {{ formatDate(item.toDate) }} <br />
                                        {{ formatForwardDate(item.toDate) }}
                                    </span>
                                    <span v-else> Nill </span>
                                </v-flex>
                                <!-- Edit Button -->
                                <v-flex xs2 text-center>
                                    <v-btn @click="editDate(item)" color="green"><v-icon
                                            style="color: white;">mdi-pencil</v-icon><span
                                            style="color: white;">Edit</span></v-btn>
                                </v-flex>
                                <!-- Delete Button -->
                                <v-flex xs2 text-center>
                                    <v-btn @click="deleteDate(item)" color="red"><v-icon
                                            style="color: white;">mdi-delete</v-icon><span
                                            style="color: white;">Delete</span></v-btn>
                                </v-flex>

                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout wrap justify-start pl-6>
            <v-flex xs3 pb-3>
                <v-btn color="primary" @click="goBack">
                    <span style="text-transform: none; font-size: 15px; color: white;">
                        GO BACK
                    </span>
                </v-btn>
            </v-flex>
        </v-layout>
        <v-dialog v-model="editDialog" max-width="500px" persistent>
            <v-card>
                <v-card-title>Edit Date</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <!-- From Date Picker -->
                            <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field clearable outlined readonly hide-details="auto" v-model="editedFromDate"
                                        v-bind="attrs" background-color="white" v-on="on" @click="menuFrom = !menuFrom"
                                        style="color: black">
                                        <template v-slot:append>
                                            <v-icon color="black">mdi-calendar</v-icon>
                                        </template>
                                        <template v-slot:label>
                                            <span class="custom-label-text" style="color: black; font-size: 14px">Edit From
                                                Date
                                                
                                                {{  }}
                                                :</span>
                                        </template>
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="editedFromDate" color="#13736f"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <!-- To Date Picker -->
                            <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field clearable outlined readonly hide-details="auto" v-model="editedToDate"
                                        v-bind="attrs" background-color="white" v-on="on" @click="menuTo = !menuTo"
                                        style="color: black">
                                        <template v-slot:append>
                                            <v-icon color="black">mdi-calendar</v-icon>
                                        </template>
                                        <template v-slot:label>
                                            <span class="custom-label-text" style="color: black; font-size: 14px">Edit To
                                                Date:</span>
                                        </template>
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="editedToDate" color="#13736f"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="saveEditedDate(itemid)" color="primary">Save</v-btn>
                    <v-btn @click="cancelEdit" color="red" dark>Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="500px" persistent>
            <v-card>
                <v-card-title>Confirm Delete</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this item?
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="confirmDelete" color="primary">Yes</v-btn>
                    <v-btn @click="cancelDelete" color="red" dark>No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
    data() {
        return {
            id: this.$route.query.id,
            deleteDialog: false,
            itemToDelete: null,
            formattedFromDate: null,
            formattedToDate: null,
            editDialog: false,
            editedFromDate: null,
            editedToDate: null,
            editedItem: null,
            itemArray2: ["Vegeterian", "Non-vegetarian"],
            curIdToDelete: null,
            name: null,
            editdialog: false,
            barlist: [],
            edit: [],
            userProfileTabs: null,
            subtab: null,
            appLoading: false,
            page: 1,
            keyArray: [],
            dialogVisible: false,
            file: null,
            currentItemId: null,
            pages: 0,
            dialog1: false,
            preview: null,
            tab: null,
            barlist1: [],
            dialog3: false,
            deletedialog: false,
            minAmount: null,
            password: null,
            categoryarray: [],
            list: [],
            blockid: this.$route.query._id,

            flag: false,
            flag2: false,
            auctionbased: [],
            currentpage: 1,
            menu1: false,
            cdate: null,
            menu2: false,
            menu3: false,
            menu4: false,
            fromDate: new Date().toISOString().substr(0, 10),
            toDate: null,
            fromDate2: null,
            toDate2: null,
            msg: null,
            currentPage: 1,
            showSnackBar: false,
            info: [],
            itemid:null,
            dialog2: false,
            viewdialog: false,
        };
    },

    mounted() {
        this.getList();
    },
    computed: {
        minDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0");
            const day = String(today.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        formattedDate() {
            const date = new Date(this.edit.selectedDate);
            return date.toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        },
    },

    methods: {
        cancelDelete() {
            this.itemToDelete = null;
            this.deleteDialog = false;
        },
        confirmDelete() {
  this.deleteDialog = false;

  if (this.itemToDelete) {
    const itemid = this.itemToDelete._id;

    axios({
      method: 'POST',
      url: '/divsion/edit/blockdates',
      headers: {
        token: localStorage.getItem('token'),
      },
      data: {
        blockid: itemid,
        status: 'delete',
      },
    })
      .then((response) => {
        console.log(response.data);
        this.msg = response.data.msg;
        this.showSnackBar = true;
        this.getList(); 
      })
      .catch((error) => {
        console.error('Error deleting date:', error);
        this.msg = 'Error deleting date. Please try again.';
        this.showSnackBar = true;
      });
  } else {
    console.error('Invalid item to delete:', this.itemToDelete);
  }
},

        deleteDate(item) {

            this.itemToDelete = item;
            this.deleteDialog = true;
        },
      
        editDate(item) {
            console.log("item.fromDate=",item.fromDate)
            var cdate = item.fromDate;
            console.log("asaas", item.fromDate);
            cdate = moment(cdate).format("YYYY-MM-DD");

            var cdate2 = item.toDate;
            console.log("asaas", item.toDate);
            cdate2 = moment(cdate2).format("YYYY-MM-DD");
            this.itemid= item._id;
            console.log("id is",this.itemid)
            
            this.editedFromDate = cdate || null;
            this.editedToDate = cdate2 || null;
            this.editedItem = item;
            this.editDialog = true;
        },
        saveEditedDate(itemid) {
    if (this.editedFromDate && this.editedToDate) {
      axios({
        method: 'POST',
        url: '/divsion/edit/blockdates',
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          blockid: itemid,
          fromDate: this.editedFromDate,
          toDate: this.editedToDate,
          status: 'edit'
        },
      })
        .then((response) => {
          console.log(response.data);
          this.editedFromDate = null;
          this.editedToDate = null;
          this.formattedEditedFromDate = null; 
          this.formattedEditedToDate = null; 
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.editDialog = false;
          this.getList(); 
        })
        .catch((error) => {
          console.error('Error updating dates:', error);
          this.msg = 'Error updating dates. Please try again.';
          this.showSnackBar = true;
        });
    } else {
      console.error('Please select both start date and end date.');
    }
  },

        cancelEdit() {
            this.editedFromDate = null;
            this.editedToDate = null;
            this.editedItem = null;
            this.editDialog = false;
        },
        goBack() {

            this.$router.go(-1);
        },
        formatDate(dateString) {

            const date = new Date(dateString);


            const options = { year: "numeric", month: "long", day: "numeric" };
            const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
                date
            );

            return formattedDate;
        },

        formatForwardDate(dateString) {

            const date = new Date(dateString);


            const options = { hour: "numeric", minute: "numeric", hour12: true };
            const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
                date
            );

            return formattedDate;
        },
        getList() {
            this.appLoading = true;
            axios({
                method: "POST",
                url: "/list/block",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    campid: this.$route.query.id,
                },
            })
                .then((response) => {
                    this.list = response.data.data.map((item) => {
                        const date = new Date(item.selectedDate);
                        item.selectedDate = date.toLocaleDateString();
                        item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
                        return item;
                    });
                    this.totalData = response.data.totalLength;
                    this.selectedDatesArray = this.list.map((item) => item.selectedDate);
                    this.pages = Math.ceil(this.totalData / response.data.limit);
                    this.appLoading = false;
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },
};
</script>