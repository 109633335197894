<template>
  <div>
    <v-app id="inspire">
      <v-img height="100vh" src="../../assets/Images/NATURESTAY.png">
        <v-layout wrap justify-center fill-height>
          <v-flex xs10 sm9 md7 lg7 px-2 align-self-center>
            <v-snackbar
              v-model="showSnackBar"
              color="black"
              right
              :timeout="timeout"
            >
              <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">
                  <span style="color: white">
                    {{ msg }}
                  </span>
                </v-flex>
                <v-flex text-right>
                  <v-btn
                    small
                    :ripple="false"
                    text
                    @click="showSnackBar = false"
                  >
                    <v-icon style="color: white">mdi-close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-snackbar>
            <v-layout wrap justify-center>

              <v-flex sm6 md6 lg6 hidden-xs-only>
                <v-card outlined color class="pa-4 gradient-background responsive-card" style="border-radius: 0px; border: transparent">
                  <v-layout wrap justify-center pt-16>
                    
                    <v-flex xs12 sm12 md12 lg12 class="fill-height">
                      <v-layout wrap justify-center align-self-center>
                        <v-flex sm8 md8 lg8>
                          <v-img src="../../assets/Images/NATURESTAY png 1.png"></v-img>
                        </v-flex>

                        <v-flex xs12 sm12 md12 lg12 class="text-center" pt-4>
                          <span style="font-size: 25px; font-weight: 600; color: white;">NATURESTAY</span>
                        </v-flex>
                        <v-flex xs12 sm12 md12 lg12 class="text-center" pt-4>
                          <span style="color: white; font-weight: 300; font-size: 14px;">Embrace the wild, discover the extraordinary</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12 sm6 md6 lg6>
                <v-card outlined color class="pa-4 responsive-card" style="border-radius: 0px; border: transparent ">
                  <v-layout wrap justify-center>
                    <v-flex>
                      <v-layout wrap justify-center>
                        
                        <v-flex xs12>
                          <v-layout wrap justify-center>
                        <!-- <v-flex xs12>
                          <v-layout wrap justify-center>
                            <v-flex xs6 pt-2 lg4 text-center>
                              <span
                                style="
                                  font-family: mainfont;
                                  font-size: 15px;
                                  color: #000;
                                "
                              >
                                Log In
                              </span>
                              <v-progress-linear
                                height="4"
                                value="100"
                                color="rgba(41, 119, 170, 1)"
                              ></v-progress-linear>
                            </v-flex>
                          </v-layout>
                        </v-flex> -->
                      </v-layout>
                    </v-flex>

                        

                        <v-flex pt-6 xs12 text-left>
                          <v-layout wrap justify-center>

                            <v-flex xs10 text-start>
                          <span
                            style="
                              font-family: mainfont;
                              font-size: 20px;
                              font-weight: 600;
                              cursor: pointer;
                              color: black;
                            "
                          >Log In
                          </span>
                        </v-flex>

                            <v-flex xs10 pt-4>
                              <v-form @submit.prevent="validateInput">
                                <v-text-field
                                  class="mainfont"
                                  color="#717171"
                                  label="Email"
                                  outlined
                                  dense
                                  style="font-family: mainfont"
                                  v-model="emailId"
                                  hide-details
                                >
                                </v-text-field>
                              </v-form>
                            </v-flex>
                            <v-flex xs10 pt-4>
                              <v-form @submit.prevent="validateInput">
                                <!-- <v-text-field
                                  color="#717171"
                                  placeholder="Password"
                                  outlined
                                  dense
                                  style="font-family: mainfont"
                                  v-model="password"
                                  hide-details
                                >
                                </v-text-field> -->
                                <v-text-field
                                  :append-icon="
                                    show3 ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  :type="show3 ? 'text' : 'password'"
                                  name="input-10-2"
                                  color="#717171"
                                  label="Password"
                                  v-model="password"
                                  outlined
                                  dense
                                  style="font-family: mainfont"
                                  class="input-group--focused"
                                  @click:append="show3 = !show3"
                                  hide-details
                                ></v-text-field>
                              </v-form>
                            </v-flex>

                            <v-flex xs10 text-center class="text-end">
                          <span style="font-family: mainfont; font-size: 14px;cursor: pointer;" @click="$router.push('/forgotpassword')">Forgot Password</span>
                        </v-flex>

                          </v-layout>
                        </v-flex>
                        <v-flex xs10 pt-8 pb-2>
                          <v-btn
                            class="no-uppercase mainfont"
                            dark
                            block
                            color="rgba(41, 119, 170, 1)"
                            @click="validateInput"
                          >
                            LOGIN
                          </v-btn>
                        </v-flex>
                        <v-flex xs10 pt-4 pb-8 text-center>
                          <span style="font-family: mainfont; font-size: 13px; font-weight: 500">Not a member yet? &nbsp;
                            <span style="font-family: mainfont; font-size: 13px; font-weight: 600; color: rgba(41, 119, 170, 1);cursor: pointer;" @click="$router.push('/register')">SIGN UP</span></span>
                        </v-flex>
                        <!-- <v-flex
                          style="cursor: pointer"
                          xs10
                          pt-4
                          pb-8
                          text-center
                          @click="$router.push('/register')"
                        >
                          <span style="font-family: mainfont"
                            >New user? Click here to register</span
                          >
                        </v-flex> -->
                        <!-- <v-flex
                          style="cursor: pointer"
                          xs10
                          
                          pb-1
                          text-center
                          @click="$router.push('/forgotpassword')"
                        >
                          <span style="font-family: mainfont"
                            >Forgot Password?</span
                          >
                        </v-flex> -->
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

          </v-flex>
        </v-layout>
      </v-img>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import store from "./../../store";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      otp: null,
      phone: null,
      emailId: null,
      password: null,
      show1: false,
      show2: true,
      show3: false,
      show4: false,
    };
  },
  mounted() {
    this.phone = this.$route.query.phone;
  },
  methods: {
    validateInput() {
      if (!this.emailId) {
        this.msg = "Please Provide Email";
        this.showSnackBar = true;
        return;
      } else {
        this.login();
      }
    },
    login() {
      const encryptedEmail = CryptoJS.AES.encrypt(this.emailId, 'natureadirgwwvn[!@#$%^&*()camp').toString();
      const encryptedPassword = CryptoJS.AES.encrypt(this.password, 'natureadirgwwvn[!@#$%^&*()camp').toString();
      axios({
        method: "post",
        url: "/user/login",
        data: {
          // emailId: this.emailId,
          // password: this.password,
          emailId: encryptedEmail,
          password: encryptedPassword,
        },
      })
        .then((response) => {
          if (response.data.status) {
            store.commit("loginUser", response.data.token);
            var uType = response.data.data.uType;
            var path = response.data.data.path;
            var role = response.data.data.role;
            // var name = response.data.data.name;
            var username =response.data.data.username;
            localStorage.setItem("uType", uType);
            localStorage.setItem("path", path);
            localStorage.setItem("username",username);
            // localStorage.setItem("name",name);
            localStorage.setItem("role",role)

            if (uType === 777) {
              this.$router.push({ path: "/DashboardPage" });
            } else if (uType === 772) {
              store.commit("loginUser", response.data.token);
              this.$router.push({ path: "/NatureCamps" });
            } else if (uType === 7741) {
              store.commit("loginUser", response.data.token);
              this.$router.push({ path: "/ClerkDashboard" });
            } else if (uType === 7742) {
              store.commit("loginUser", response.data.token);
              this.$router.push({ path: "/SuperintedentDashboard" });
            }else if (uType === 7771) {
              store.commit("loginUser", response.data.token);
              this.$router.push({ path: "/cwwclerkdashboard" });
            }else if (uType === 7772) {
              store.commit("loginUser", response.data.token);
              this.$router.push({ path: "/CwwministerialDashboard" });
            } else {
              this.$router.push({ path: "/divisiondashboard" });
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
   

<style>
.gradient-background {
  background: linear-gradient(to bottom, rgba(46, 148, 213, 1), rgba(17, 72, 107, 1));
}
@media (min-width: 1264px) { /* lg */
    .responsive-card {
      height: 400px;
    }
  }
  @media (min-width: 960px) and (max-width: 1263px) { /* md */
    .responsive-card {
      height: 380px;
    }
  }
  @media (min-width: 600px) and (max-width: 959px) { /* sm */
    .responsive-card {
      height: 380px;
    }
  }
</style>
